import React, { useState } from 'react';
import useStyles from '../../utils/styles';
import Constants, { VColor } from '../../utils/constants';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

export default function FilledTextInput({ 
  label, 
  type, 
  name, 
  showError, 
  value, 
  placeholder, 
  formFields, 
  removable, 
  editMode, 
  onDelete, 
  disable = false, 
  display = 0, 
  maxLength 
}) {
  const [focused, setFocused] = useState(false);
  const [input, setInput] = useState(value || '');
  const [isEmpty, setIsEmpty] = useState(!value);
  const [warning, setWarning] = useState('');
  const classes = useStyles();

  const loadData = (val, placeholder) => {
    if (formFields !== undefined) {
      formFields(val, placeholder);
    }
  };

  const changeHandle = (e) => {
    const newValue = e.target.value;
    if (maxLength && newValue.length > maxLength) {
      if (type === 'number' || type === 'phone') {
        setWarning(`The maximum input is ${maxLength} numbers.`);
      }
      else {
        setWarning(`The maximum input is ${maxLength} characters.`);
      }
    } else {
      setWarning('');
      setIsEmpty(!newValue);
      setInput(newValue);
      loadData(newValue, placeholder);
    }
  };

  return (
    <div
      style={{
        backgroundColor: VColor.lightGray,
        padding: 5,
        marginBottom: 20,
        position: 'relative'
      }}
    >
      <div className={classes.labelText} style={{ marginLeft: 3 }}>
        {label}
      </div>
      <input
        className={classes.mainText}
        type={type}
        value={input}
        name={name}
        placeholder={placeholder}
        onChange={changeHandle}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        style={{
          width: '100%',
          borderWidth: 0,
          backgroundColor: VColor.lightGray,
          outline: 'none',
          borderBottomWidth: focused ? 2 : 0,
          borderBottomColor: VColor.blue
        }}
        disabled={disable}
        maxLength={maxLength +1} // HTML attribute to enforce max length
      />
      {(showError && isEmpty) && <div className={classes.errorText}>{showError}</div>}
      {warning && <div className={classes.errorText}>{warning}</div>}
      {
        removable && display === Constants.displaySide.front ?
          <div className={classes.dispFrontBack} style={{ background: 'white' }} /> :
          removable && display === Constants.displaySide.back ?
            <div className={classes.dispFrontBack} style={{ background: 'black' }} /> : null
      }
      {
        removable && editMode && (
          <IconButton style={{ position: 'absolute', right: 0, top: 10 }} onClick={onDelete}>
            <Delete />
          </IconButton>
        )
      }
    </div>
  );
}

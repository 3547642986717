import React from 'react'
import { Link,navigate } from 'gatsby'
import axios from 'axios'
import Container from '@material-ui/core/Container'
import { VColor } from '../../../utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import Select from '@material-ui/core/Select'
import FilledTextInput from '../../../components/scan-card/FilledTextInput'
import Utils from '../../../utils/utils'
import { InputLabel, FormControl, Input, InputAdornment, IconButton } from '@material-ui/core';
import {
  Paper,
  Button,
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { serverURL } from '../../../utils/RestAPI'
import useStyles from '../../../utils/styles'
import { MainLayout } from '../../../components/Layout'
import MenuItem from '@material-ui/core/MenuItem'



class LoginMessage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLogin: true,
      domain_message: ``,
      domainlist: [],
      domain_id: '',
    };
  }
   

  componentDidMount() {
    const { dispatch, userData, basicData } = this.props
    this.loadDomainlist();
  }

  onSignup = () => {}

  onSignin = () => {}

  
  handleChange = event => {
    this.setState({ email: event.target.value })
  }
  
  loadDomainlist(){
    const token = localStorage.getItem('token');
    const beartoken = "Bearer " + token;
    const headers = { 
      'Authorization': beartoken
    };
    const DOMAIN_URL = serverURL + '/api/domains'

    return new Promise(resolve => {
       setTimeout(() => {
        axios.get(DOMAIN_URL, { headers })
        .then(response => {
          const domaindata = response.data.data;
          let d_id = 0;
          domaindata.map((one, index) => {
           if (one.domain_name === this.props.location.state.domain_name){
             d_id = one.domain_id
           }
          })
          this.setState({domainlist: domaindata, domain_id: d_id})
          resolve(domaindata);
        })
        .catch((error) => {
          console.log(error);
          return null;
        }) 
      }, Math.random() * 500 + 100) // simulate network latency
    })
  }

  loadLoginMessage(domain_id) {
    const token = localStorage.getItem('token');
    const beartoken = "Bearer " + token;
    const headers = { 
      'Authorization': beartoken
    };
    const LOGIN_MESSAGE_URL = `${serverURL}/api/domainMessage/${domain_id}`;

    axios.get(LOGIN_MESSAGE_URL, { headers })
      .then(response => {
        const { domain_message } = response.data.data;
        this.setState({ domain_message: domain_message || '' });
      })
      .catch(error => {
        console.log(error);
        this.setState({ domain_message: '' });
      });
  }

  handleDomainChange = e => {
    const domain_id = e.target.value;
    this.setState({ domain_id });
    this.loadLoginMessage(domain_id);
  }

  handleSave = () => {
    const token = localStorage.getItem('token');
    const beartoken = "Bearer " + token;
    const headers = {
      'Authorization': beartoken
    };
    const DOMAIN_MESSAGE_URL = serverURL + '/api/domainMessage';

    const body = {
      domain_id: this.state.domain_id,
      message: this.state.domain_message,
    };

    axios.put(DOMAIN_MESSAGE_URL, body, { headers })
      .then(response => {
        console.log('Message saved successfully:', response.data);
        navigate('/admin'); // Navigate to admin page after successful save
      })
      .catch(error => {
        console.error('Error saving message:', error);
      });
  }
  render() {
    const { userData, classes } = this.props

    return (
      <MainLayout menuIndex={4}>
        <Container maxWidth="sm">
          <Paper elevation={0}>
          <div style={{backgroundColor: VColor.lightGray, padding: '0 5px'}}>
                  <FormControl fullWidth>
                    <InputLabel id="label-domain-name">Domain Name</InputLabel>
                    <Select
                      labelId="label-domain-name"
                      id="date-domain-select"
                      value={this.state.domain_id}
                      fullWidth
                      onChange={this.handleDomainChange}>
                      { this.state.domainlist.length > 0 ? this.state.domainlist.map(one => {
                        return(<MenuItem value={one.domain_id} key={one.domain_id}>{one.domain_name}</MenuItem>)
                      }) : null }
                    </Select>
                  </FormControl>
                </div> 
     
            <div style={{ width: '100%', textAlign: 'left' }}>
              <Typography variant="h5" style={{ fontWeight: 'lighter' }}>
                Login Message
              </Typography>
            </div>
            <div style={{ marginTop: 20 }}>
              <TextField
                id="outlined-multiline-static"
                label=""
                multiline
                fullWidth
                rows={20}
                //   defaultValue={this.state.message}
                variant="outlined"
                value={this.state.domain_message}
                onChange={e => {
                  this.setState({ domain_message: e.target.value })
                }}
                style={{height: '50%', minHeight: 400}}
              />

              <div style={{ marginTop: 30 }}>
                 <div style={{ marginTop: 30 }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ float: 'right', textTransform: 'none' }}
                  onClick={this.handleSave}
                >
                  Save
                </Button>
              </div>
              </div>
            </div>
            </Paper>
       
        </Container>
      </MainLayout>
    )
  }
}

export default function(props) {
  const dispatch = useDispatch()
  const userData = useSelector(state => state.app.userData)
  const classes = useStyles()

  return (
    <LoginMessage
      {...props}
      dispatch={dispatch}
      userData={userData}
      classes={classes}
    />
  )
}
